body {
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 0.8em;
}

body {
  color: var(--cell-color);
  font-family: var(--main-font);
}

:root {
  /* grid font colors */
  --cell-color: #0E0E09;
  --cell-color-found: #FFFFFF;
  --cell-color-missed: #7C7C77;


  --cell-color-font-size: 32px;
  --cell-color-font-size-small: 10px;
  --cell-color-font-size-tiny: 8px;

  --board-background-storke: #5E6066;

  /* keyboard colors */
  --key-normal-color: #111418;
  --key-missed-color: #111418;
  --key-found-color: #FFFFFF;

  --button-corner-radius: 60px;

  /* grid background colors */
  --green-color: #80B50E;
  --yellow-color: #EAC632;
  --missing-color: #B0A7A1;
  --empty-color: #FDF6F1;

  --brand-orange: #E88D4C;

  --tile-border-radius: 4px;
  --keyboard-border-radius: 10px;

  --panel-bg-color: #FFFFFF;
  --panel-border-color: #EDF1F2;
  --panel-border-radius: 5px;

  --button-primary-bg-color: #89DB21;
  --button-primary-color: #FFFFFF;
  --button-secondary-bg-color: #000000;
  --button-secondary-color: #FFFFFF;

  --main-font: "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;

  --stat-bar-background: #D2D3D8;
  --stat-bar-fill: #89DB21;

  --banner-font-color: #111418;
  --banner-background-info: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(210,211,216,1) 100%);
  --banner-background-reveal: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(210,211,216,1) 100%);
  --banner-font-size-info: 16px;
  --banner-font-size-reveal: 24px;
  --banner-height: 34px;
}

#helpBtn {
  background: url(./assets/icon-info.svg);
  background-size: contain;
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
}

#challengeBtn {
  background: url(./assets/icon-challenge.svg);
  background-size: contain;
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
}

#settingsBtn {
  background: url(./assets/icon-settings.svg);
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;

}

#statsBtn {
  background: url(./assets/icon-stats.svg);
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
}

button, button.primary {
  background-color: var(--button-primary-bg-color);
  border: none;
  border-radius: var(--button-corner-radius);

  font-family: var(--main-font);
  color: var(--button-primary-color);
  cursor: pointer;
  font-size: 1.1em;
  font-weight: bold;
  padding: 10px 60px;
  outline: none;
}

button.twitter {
  background-color: #00ACEE;
  /* background-image: url(./assets/twitterIcon.png); */
  background-size: 15%;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 15px;
}

button.share {
  /* background-image: url(./assets/shareIcon.png); */
  background-size: 15%;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 15px;
}

button.primary.outline {
  background-color: #FFFFFF;
  border: solid 2px var(--button-primary-bg-color);
  color: var(--button-primary-bg-color);
}

button.secondary.outline {
  background-color: #FFFFFF00;
  border: solid 2px var(--button-secondary-bg-color);
  color: var(--button-secondary-bg-color);
}

button.secondary {
  background-color: var(--button-secondary-bg-color);
  border: none;
  color: var(--button-secondary-color);
}

.green {
  color: var(--green-color);
}

.yellow {
  color: var(--yellow-color);
}

.missed {
  color: var(--missing-color);
}

.grid > div> div {
  border: 1px solid;
}

.grid > div > div.letterBull {
  background-size: cover;
  background-color: var(--green-color);
  border-radius: var(--tile-border-radius);
  color: var(--cell-color-found);
  font-size: var(--cell-color-font-size);
}

.grid > div > div.letterCow {
  background-size: cover;
  background-color: var(--yellow-color);
  border-radius: var(--tile-border-radius);
  color: var(--cell-color-found);
  font-size: var(--cell-color-font-size);
}

.grid > div > div.letterNone {
  background-size: cover;
  background-color: var(--missing-color);
  border-radius: var(--tile-border-radius);
  color: var(--cell-color-missed);
  font-size: var(--cell-color-font-size);
}

.grid > div > div.letterEmpty {
  background-size: cover;
  background-color: var(--empty-color);
  border-radius: var(--tile-border-radius);
  border-color: var(--brand-orange);
  color: var(--key-missed-color);
  font-size: var(--cell-color-font-size);
}

.grid.small > div > div.letterCow,
.grid.small > div > div.letterBull,
.grid.small > div > div.letterEmpty,
.grid.small > div > div.letterNone {
  font-size: var(--cell-color-font-size-small)
}

.grid.tiny > div > div.letterCow,
.grid.tiny > div > div.letterBull,
.grid.tiny > div > div.letterEmpty,
.grid.tiny > div > div.letterNone {
  font-size: var(--cell-color-font-size-tiny)
}


#playingScreen .grid {
  background-color: #FFFFFF;
  border-radius: 5px;
  /*border: #5E6066 solid 1px;*/
}

.keyboard > div > div {
  border-radius: 2px;
  box-shadow: 0px 3px 0px #868B8C;
  font-family: 'Helvetica Neue';
  font-weight: 400;
  font-size: 14px;
}

.keyboard > div > div:active {
  transform: translateY(3px);
  box-shadow: 0px 0px 0px #868B8C;
}

.keyboard > div > div.letterBull {
  background-color: var(--green-color);
  color: var(--key-found-color)
}

.keyboard > div > div.letterCow {
  background-color: var(--yellow-color);
  color: var(--key-found-color)
}

.keyboard > div > div.letterNone {
  background-color: var(--missing-color);
  color: var(--cell-color-missed);
}

.keyboard > div > div.letterEmpty {
  background-color: #F1ECE9;
  color: #2C2C44;
}

.keyboard > div > div.enter {
  padding: 0 3px;
  width: 50px;
}

.keyboard > div > div.delete {
  padding: 0 3px;
  width: 50px;
}

.keyboard > div > div.enter img,
.keyboard > div > div.delete img {
  width: 40%;
}

.App .primary div.gridConstraint {
}

#helpScreen .examples {
  margin-top: 10px;
  text-align: left;
}

#helpScreen .examples .content {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#helpScreen button {
  font-size: 18px;
}

#helpScreen .examples .help-title {
  font-family: 'Helvetica Neue';
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  color: var(--brand-orange);
  margin: 10px 0;
}

#helpScreen .examples .gtw {
  font-family: 'Headland One';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 5px;
}

#helpScreen .examples .howtoplay {
  font-family: 'Helvetica Neue';
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
}

#helpScreen .examples .hint {
  font-family: 'Helvetica Neue';
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #2C2C44;
}

span.green {
  color: var(--green-color);
  font-weight: 800;
}

span.yellow {
  color: var(--yellow-color);
  font-weight: 800;
}

#helpScreen .ugtp {
  font-family: 'Headland One';
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--brand-orange);
}