* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.App {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.App header {
}

.App header nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.App header nav h1 {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  font-size: 50px;
  font-weight: 400;
  line-height: 50px;
  font-family: 'DM Serif Display', serif;
}

.App header nav .button {
  width: 34px;
  height: 34px;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.App header nav .button.noclick {
  cursor: auto;
}

.App .mainArea {
  flex-grow: 1;
}

.App .gameScreen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.App .gameScreen .resultContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
}

.keyboard {
  min-height: 200px;
  position: relative;
  top: 10px;
}

.App .gameScreen .resultContent .shareButtons button {
  font-size: 18px;
  margin-left: 5px;
  margin-right: 5px;
  
}

.resultContent .triggerText {
  font-family: 'American Typewriter', serif;
  font-weight: bold;
  font-size: 1.1em
}

.App .gameScreen .resultContent .shareButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.App .gameScreen .resultContent button.secondary {
  margin: 5px;
  margin-top: 10px;
  font-size: 10px;
}

.App .gameScreen div.primary {
  flex-grow: 1;
  min-height: 0;
  overflow: auto;
}

.App .gameScreen .gridConstraint {
  margin: 0 auto;
}

#settingsScreen button {
  margin: 10px 0;
}

#helpScreen {
  max-width: 800px;
  margin: 0 auto;
  font-size: 1em;
}

#helpScreen button {
  margin: 0 5px;
}

.bannerContainer {
  position: relative;
  height: 24px;
}

.banner {
  display: flex;
  flex-direction: column;;
  align-items: center;
  justify-content: center;
}

.banner {
  height: 0px;
  text-align: center;
  overflow: hidden;
}

.banner.info {
  background: rgb(2,0,36);
  background: var(--banner-background-info);
  font-size: var(--banner-font-size-info);
  color: var(--banner-font-color);
}

.banner.reveal {
  background: rgb(2,0,36);
  background: var(--banner-background-reveal);
  font-size: var(--banner-font-size-reveal);
  color: var(--banner-font-color);
  text-transform: uppercase;
  font-weight: bold;
}

#helpScreen .gridConstraint  {
  margin: 0 auto;
}

#helpScreen button {
  margin-top: 10px;
}

#helpScreen p {
  margin: 0px;
}

.App .statsView h3, .App .statsView h2 {
  text-transform: uppercase;
}

.App .statsView .metrics {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App .statsView .metrics > div {
  margin: 0 10px;
  width: 80px;
}

.App .statsView .metrics > div > div:first-child {
  font-size: 0.8em
}

.App .statsView .metrics > div > div:last-child {
  font-size: 1.4em
}

.statsView div.wins {
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 80%;
}

.statsView div.streak {
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
}

.statsView div.winPerc {
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 70%;
}

.App .panel {
  max-width: min(600px, 100% - 20px);
  padding: 10px;
  background-color: var(--panel-bg-color);
  border: var(--panel-border-color) solid 1px;
  border-radius: var(--panel-border-radius);
}

.panel ::-webkit-scrollbar {
  width: 4px;
}

.App .panel .content.scrollable {
  overflow-y: scroll;
  clear: both;
  padding-top: 0;
  padding-bottom: 10px;
}

.panel ::-webkit-scrollbar-track {
  display: none;
}

.panel .content.scrollable h3,
.panel .content.scrollable h2,
.panel .content.scrollable h1 {
  margin: 0;
}

.panel ::-webkit-scrollbar-thumb {
  background: white;
  width: 8px;
}

@media only screen and (max-width: 600px) {
  .App .panel {
    margin: 0 10px;
  }
}

@media only screen and (min-width: 600px) {
  .App .panel {
    margin: 0 auto;
  }
}

.distributions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}

.distributions .bars, .distribution .text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 2px 0;
  font-size: .8em;
}

.distributions .bars .barBackground {
  max-width: 80px;
  height: 15px;
  background-color: var(--stat-bar-background);
  display: flex;
  margin-right: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  flex-grow: 1;
  border-radius: 2px;
}

.distributions .bars .bar {
  height: 15px;
  background-color: var(--stat-bar-fill);
  border-radius: 2px;;
}

.distributions .text .stat {
  text-align: left;
  line-height: 19px;
  display: block;
}

.App .panel {
  position: relative;
}

.App .panel .content {
  padding: 10px 0px;
}

.App .panel .x {
  width: 40px;
  height: 40px;
  top: 10px;
  right: 10px;
  background-image: url(./skin/assets/icon-close.svg);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  position: absolute
}

.App .gameScreen .bottom {
  display: grid;
  height: 200px;
  flex-grow: 0;
}

.keyboardContainer {
  grid-column: 1;
  grid-row: 1;
}

.resultsContainer {
  grid-column: 1;
  grid-row: 1;
}

.homeView {
  max-width: 800px;
  margin: 0 auto;
  height: 100%;
  overflow-y: hidden;
}

.homeViewScroll {
  overflow-y: scroll;
}

.App .statsView button {
    margin-top: 10px;
    font-size: 18px;
  }

  .profileItem {
    cursor: pointer;
  }

  .bannerContainer img {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-right: 10px;
  }

  .banner {
    display: flex;
    flex-direction: row;
  }

  .loadingScreen {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gameScreen .profileTweetContainer {
    margin-top: 5px;
    flex-grow: 0;
  }

  .keyboard .message {
    font-family: 'American Typewriter', serif;
  }

body.modalisopen {
  overflow-y: hidden;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.modal__container {
  padding: 15px;
  border: 1px solid #2C2C44;
  background-color: #fff;
  width: 100%;
  max-width: 420px;
  min-height: 350px;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__container__closebtn {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
}

.modal__container__closebtn img {
  width: inherit;
  height: inherit;
}

.resultsmodal__header {
  margin-bottom: 25px;
}

.resultsmodal__header__title {
  font-family: var(--main-font);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--brand-orange);
  margin: 0;
  padding: 0;
}
.resultsmodal__playtounlock {
  font-family: #000;
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 0 50px 0;
  padding: 0;
  color: #B0A7A1;
}
.resultsmodal__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
}
.resultsmodal__actions__button {
  font-family: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;    
  padding: 20px;
  border-radius: 0;
  width: 100%;
  margin: 0;
}
.resultsmodal__actions__button:last-child {
  margin-right: 0;
}
.resultsmodal__actions__button--share {    
  color: #fff;
  background-color: #80B50E;
  border: 1px solid #80B50E;
}
.resultsmodal__actions__button--continue {
  border: 1px solid var(--brand-orange);
  color: var(--brand-orange);
  background-color: #fff;
  width: 100%;
}
.resultsmodal__actions__button--playnext {
  background-color: transparent;
  color: #B0A7A1;
  text-decoration: underline;
  border: none;
  width: auto;
  margin: 10px 0 0 0;
  padding: 5px 10px;
  font-weight: 800;
  font-family: 'Helvetica Neue';
}

.resultsmodal__content {
  align-self: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.resultsmodal__version {
  font-family: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;    
  color: var(--brand-orange);
  margin: 0;
  padding: 0;
}
.resultsmodal__fullverse {
  font-family: var(--main-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  margin: 15px 0;
}
.resultsmodal__fullverse div {
  display: inline;
  filter: blur(4px);
  -webkit-filter: blur(4px);
}
.resultsmodal__fullverse span {
  display: inline;
  color: #80B50E;
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

.resultsmodal__fullverse.solved div,
.resultsmodal__fullverse.solved span {
  filter: blur(0px);
  -webkit-filter: blur(0px);
}

.resultsmodal__versedisplay {
  font-family: 'Headland One';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #2C2C44;
  margin: 0;
  padding: 0;
}

playpass-share {
  z-index: 5;
}

.App .gameScreen .game-hint {
  padding: 5px;
  border-radius: 3px;
  width: calc(100% - 20px);
  max-width: 400px;
  margin: 0 auto;
  user-select: none;
}

.App .gameScreen .game-hint .game-hint-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
}

.App .gameScreen .game-hint .game-hint-head .toggle-switch {
  margin-left: auto;
}

.App .gameScreen .game-hint .current-verse {
  text-align: left;
  font-weight: 800;
  font-size: 14px;
  color: var(--brand-orange);
  font-family: 'Helvetica Neue';
  margin: 0;
}

.App .gameScreen .game-hint .verse-hints {
  text-align: left;
}

.App .gameScreen .game-hint .verse-hints div {
  font-size: 12px;
  font-weight: 400;
  font-family: 'Headland One';
  text-align: left;
  display: inline;
}

.App .gameScreen .game-hint .verse-hints div span {
  color: #80B50E;
  font-weight: 400;
}

.App .gameScreen .game-hint .verse-hints div div,
.App .gameScreen .game-hint .verse-hints div span {
  display: inline;
  filter: blur(4px);
  -webkit-filter: blur(4px);
  transition: 1s filter linear;
  -webkit-transition: 1s filter linear;
}

.App .gameScreen .game-hint .verse-hints div.unblur div,
.App .gameScreen .game-hint .verse-hints.solved div.unblur span {
  display: inline;
  filter: blur(0px);
  -webkit-filter: blur(0px);
}

.App .gameScreen .bottom {
  position: relative;
}

.App .gameScreen .bottom .result-container {
  width: 100%;
  position: absolute;
  right: 0;
  transition: .5s top ease-in-out;
  user-select: none;
  background-color: #fff;
  box-shadow: 0px 3px 60px 5px #3C302880;
  height: 175%;
  top: 175%;
}

.App .gameScreen .bottom .result-container .weeklyprogress {
  margin: 0 0 20px 0;
}

.App .gameScreen .bottom .result-container.open {
  top: -75%;
}

.App .gameScreen .bottom .result-container-content {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  height: 100%;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.result-container-content .result-msg {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  font-family: 'Headland One';
  color: var(--brand-orange);
  margin-bottom: 5px;
}

.result-container-content .share-msg {
  text-align: center;
  font-weight: 400;
  font-family: 'Headland One';
  font-size: 15px;
  margin-bottom: 10px;
}

.result-container-content .action-group {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.result-container-content .action-group button {
  font-family: 'Headland One';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;    
  padding: 10px 5px;
  border-radius: 0;
  width: 100%;
}

.result-container-content .action-group button:nth-child(1) {
  color: #fff;
  background-color: #80B50E;
  border: 1px solid #80B50E;
}

.result-container-content .action-group button:nth-child(2) {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  background-color: transparent;
  color: #B0A7A1;  
  border: none;
  padding: 5px 10px;
  text-decoration: underline;
}

.weeklyprogress {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 10px;
  background-color: #F1ECE9;
  position: relative;
}

.weeklyprogress__ribbon {
  position: absolute;
  top: 5px;
  right: -2px;
  background-color: #E88D4C;
  font-size: 8px;
  width: 80px;
  height: 18px;
  line-height: 18px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
}

.weeklyprogress__ribbon__content {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
}

.weeklyprogress__ribbon__content::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 3px solid #F1ECE9;
  left: 0;
  top: 0;
}


.weeklyprogress__title {
  font-family: 'Helvetica Neue';
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  color: #E88D4C;
  margin-bottom: 5px;
}

.weeklyprogress__week {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
}

.weeklyprogress__calendaritem {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: calc(100% / 7);
}

.weeklyprogress__calendaritem::after {
  content: '';
  padding: 140% 0 0 0;
  width: 100%;
}

.weeklyprogress__calendaritem__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.weeklyprogress__calendaritem__content__day {
  padding: 3px 0;
  font-family: 'Headland One';
  font-size: 9px;
  color: #fff;
  background-color: #939393;
  width: 100%;
  display: inline-block;
  text-align: center;
}

.weeklyprogress__calendaritem__content__icon {
  padding: 0;
  background-color: #D9D9D9;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.weeklyprogress__calendaritem__content__icon svg {
  width: 40%;
}

.weeklyprogress__calendaritem__content__icon svg path {
  fill: #939393;
}

.weeklyprogress__calendaritem--progress {}

.weeklyprogress__calendaritem--progress .weeklyprogress__calendaritem__content__day {
  background-color: #80B50E;
  color: #fff;
}

.weeklyprogress__calendaritem--progress .weeklyprogress__calendaritem__content__icon {
  background-color: #D1F2C1;
}

.weeklyprogress__calendaritem--progress .weeklyprogress__calendaritem__content__icon svg path {
  fill: #80B50E;
}

.weeklyprogress__calendaritem--today .weeklyprogress__calendaritem__content__day {
  background-color: #80B50E;
  color: #fff;
}

.weeklyprogress__calendaritem--today .weeklyprogress__calendaritem__content__icon {
  background-color: #D1F2C1;
  border: 2px solid #80B50E;
  border-top: 0;
}

.weeklyprogress__calendaritem--today .weeklyprogress__calendaritem__content__icon svg path {
  fill: #80B50E;
}

.weeklyprogress__calendaritem--future .weeklyprogress__calendaritem__content__day {
  background-color: #939393;
  color: #fff;
}

.weeklyprogress__calendaritem--future .weeklyprogress__calendaritem__content__icon {
  background-color: #D9D9D9;
}

.weeklyprogress__calendaritem--future .weeklyprogress__calendaritem__content__day svg path {
  fill: transparent;
}

.toggle-switch {
  position: relative;
  width: 50px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  margin: 0;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner:before,
.toggle-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  line-height: 21px;
  font-size: 9px;
  color:white;
  font-weight: bold;
  box-sizing: border-box;
  font-family: 'Headland One';
}
.toggle-switch-inner:before {
  content: attr(data-yes);
  padding-left: 5px;
  background-color: #E88D4C;
  color: #fff;
}

.toggle-switch-inner:after {
  content: attr(data-no);
  padding-right: 5px;
  background-color: #80B50E;
  color: #fff;
  text-align: right;
}
.toggle-switch-switch {
  display: block;
  width: 15px;
  height: 15px;
  margin: 2px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 31px;
  border: 0 solid #bbb;
  border-radius: 100%;
  transition: all 0.3s ease-in 0s;
  box-shadow: 0px 2px 0px #868B8C;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}



/* ABTEST SPECIFIC STYLES */
